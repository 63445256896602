import React from 'react';
import ServicesCard from './ServicesCard';
import image1 from '../images/services/mudanzas.png'
import image2 from '../images/services/transporte-cargas.png'
import image3 from '../images/services/encomiendas.png'
import image4 from '../images/services/cargas-exclusivas.png'
import image5 from '../images/services/carga-consolidada.png'

const Services = () => {
    return (
        <div id='services'>
            <h1>Nuestros Servicios</h1>
            <p>En VJ Transportes contamos con diversos servicios a disposición de nuestros clientes</p>
            <div className='a-container'>
                <ServicesCard image={image1} title="Mudanzas" text="Contamos con servicio de mudanzas desde Santiago a Regiones" />
                <ServicesCard image={image2} title="Transporte de Cargas" text="Transportamos grandes cargas, como carros de arrastre, vehículos, etc." />
                <ServicesCard image={image3} title="Encomiendas" text="Hacemos que tus encomiendas lleguen a destino en excelentes condiciones" />
                <ServicesCard image={image4} title="Carga Exclusiva" text="Entregamos exclusividad a tu transporte si así lo requieres" />
                <ServicesCard image={image5} title="Carga Consolidada" text="Si su carga no requiere un vehículo completo, lo consolidamos" />
            </div>
        </div>
    )
}

export default Services;