import React from 'react'

const ServicesCard = (props ) => {
    return (
        <div className='a-box'>
            <div className='a-b-img'>
                <img src={props.image} alt="" />
            </div>
            <div className='a-b-text'>
                <h2> {props.title} </h2>
                <p > {props.text} </p>
                {/* <button className='productbox-button'>+ conoce más</button> */}
            </div>
        </div>
    )
}

export default ServicesCard
