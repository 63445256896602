import React from 'react'
import f from '../iconos/svg/003-facebook.svg'
import w from '../iconos/svg/001-whatsapp.svg'
import i from '../iconos/svg/004-instagram-1.svg'

const About = () => {
    return (
        <div id='about'>
            <h1>Conócenos</h1>
            <p>Un poco de la historia de VJ Transportes y sus pilares para construír la mejor entrega de servicios.</p>
            <div className="p3 text-center about-text">
            En 2020 y durante la crisis que afectó a todo el mundo, un par de colegas y amigos del área del transporte, decidieron comenzar a realizar su sueño realidad, conformando su tan anhelada empresa de servicios de transportes. Cada uno con más de 5 años de experiencia en el rubro, dueños de sus propias máquinas y con la experiencia de lo bueno y lo malo, decidieron que era el momento de entregar un servicio más amigable y cercano con sus clientes.
            </div>
            <h2>Síguenos</h2>
            <div className="rs">
                <div className="rs-item">
                    <a target="_blank" href="https://www.instagram.com/vj.transportees">
                        <img src={i} alt="instagram" />
                    </a>
                </div>
                <div className="rs-item">
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=100070307119252">
                        <img src={f} alt="facebook" />
                    </a>
                </div>
                <div className="rs-item">
                    <a target="_blank" href="https://wa.me/+56957791573">
                        <img src={w} alt="whatsapp" />
                    </a>
                </div>
            </div>
        </div>
    )
}

export default About
