import React, {useState} from 'react'
import { Link } from 'react-scroll';
import logo from '../images/logo.jpg'

const Navbar = () => {

    const [nav, setNav] = useState(false);
    const changeBackground = () => {
        (window.scrollY >= 50) ? setNav(true) : setNav(false);
    }

    window.addEventListener('scroll', changeBackground);

    return (
        <nav className={nav ? "nav active" : "nav"}>
            <Link to='main' className={nav ? 'logo small': 'logo'} smooth={true} duration={1500}>
                <img src={logo} alt="" />
            </Link>
            <input type="checkbox" className='menu-btn' id='menu-btn' />
            <label className='menu-icon' for='menu-btn'>
                <span className='nav-icon'></span>
            </label>
            <ul className="menu">
                <li> <Link smooth={true} duration={1500} to="services">Servicios</Link> </li>
                <li> <Link smooth={true} duration={1500} to="about">Conocenos</Link> </li>
                <li> <Link smooth={true} duration={1500} to="contact">Contacto</Link> </li>
            </ul>
        </nav>
    )
}

export default Navbar;