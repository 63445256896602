import React, {useRef} from 'react'
import emailjs from 'emailjs-com'
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
const MySwal = withReactContent(Swal)


const Contact = () => {

    const captcha = useRef(null);

    const enviar = (e) => {
        MySwal.showLoading();
        e.preventDefault();

        if(captcha.current.getValue()) {
            if (document.getElementsByName('nombre')[0].value === '' || 
                document.getElementsByName('correo')[0].value === '' ||
                document.getElementsByName('telefono')[0].value === '' ||
                document.getElementsByName('asunto')[0].value === '' ||
                document.getElementsByName('mensaje')[0].value === '') {
                MySwal.fire({
                    icon: 'warning',
                    title: 'Ups',
                    text: 'Para una mejor atención, debes ingresar todos los campos requeridos',
                    footer: 'Nombre, Correo, Teléfono, Asunto, Mensaje y Captcha son necesario',
                    confirmButtonText: 'Entendido',
                    confirmButtonColor: '#f95800',
                })
            } else {
                emailjs.sendForm('service_lkxb217', 'template_84lyzyp', e.target, 'user_A7lB8PIgxSkYzAFecSN5K')
                .then((result) => {
                    MySwal.fire({
                        icon: 'success',
                        title: <p>Mensaje Enviado</p>,
                        text: 'Pronto nos pondremos en contacto contigo',
                        confirmButtonColor: '#f95800',
                        confirmButtonText: 'Gracias'
                        })
                }, (error) => {
                    MySwal.fire({
                        icon: 'error',
                        title: <p>Oush</p>,
                        text: <p>Tu mensaje no pudo ser enviado, por favor intenta nuevamente</p>,
                        confirmButtonText: 'Entendido',
                        confirmButtonColor: '#f95800'
                    });
                    console.log(error.text);
                });
            }
        } else {
            MySwal.fire({
                icon: 'warning',
                iconColor: '#f95800',
                title: '¿Eres un robot?',
                text: 'Por favor verifica que eres humano',
                confirmButtonColor: '#f95800',
                confirmButtonText: 'Entendido'
            });
        }

    }

    const onChange = () => {
        console.log(captcha.current.getValue());
    }

    return (
        <div id='contact'>
            <h1>Contáctanos</h1>
            <p>Para una atención más personalidad y la entrega de un mejor servicio, llena el siguiente formulario.</p>
            <form onSubmit={enviar} name="contactForm">
                <div className="form-group">
                    <div className="form-input wm50">
                        <input type="text" placeholder="Nombre *" name="nombre" />
                    </div>
                    <div className="form-input wm50">
                        <input type="text" placeholder="Empresa" name="empresa" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-input wm50">
                        <input type="text" placeholder="Correo *" name="correo" />
                    </div>
                    <div className="form-input wm50">
                        <input type="text" placeholder="Teléfono *" name="telefono" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-input wm100">
                        <input type="text" placeholder="Asunto *" name="asunto" />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-input wm100">
                        <textarea placeholder="Mensaje *" name="mensaje"></textarea>
                    </div>
                </div>
                <ReCAPTCHA
                    ref={captcha}
                    sitekey="6Ld1598bAAAAACxSp_hMvvwUkyyjqICdZvQuYIMm"
                    onChange={onChange}
                />
                <input type="submit" value="Enviar" />
            </form>
        </div>
    )
}

export default Contact
