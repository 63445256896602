import React from 'react'

const Header = () => {
    return (
        <div id='main'>
            <div className="header-heading">
                <h3>VJ Transportes</h3>
                <h1>En <span>todas</span> las rutas de Chile</h1>
            </div>
        </div>
    )
}

export default Header
